<template>
    <div class="contact">
        <!--START Principal-->
        <section class="main-contact pt-5">
            <div class="container">
                <div class="row">
                    <div class="col-lg-7">
                        <div class="p-4"></div>
                        <h2 class="w3-text-white w3-jumbo"><span style="background:rgba(88, 88, 88, 0.2);">Contact us directly with a message!</span></h2>
                        <p class="w3-text-white w3-large mt-4 mr-4"><span style="background:rgba(88, 88, 88, 0.2);">Come to us and we will gladly assist you</span></p>
                        <a href="#formulariosection" class="w3-button w3-black w3-hover-light-gray p-3">Contact Us Now!</a>
                        <div class="p-4"></div>
                    </div>
                </div>
            </div>
        </section>
        <!-- END Principal-->
        <!--START CONTACT FORM -->
        <section class="w3-white pt-5 pb-5" id="formulariosection">
            <div class="container">
                <div class="row">
                    <div class="col-sm-6">
                        <!--FALTA UNA IMAGEN-->
                        <h4>Our social networks</h4>
                        <ul>
                            <li class="list-inline-item"><a href="https://www.facebook.com/bravewolfdev"><i class="fa fa-facebook"></i></a></li>
                            <li class="list-inline-item"><a href="https://twitter.com/BraveWolfDev"><i class="fa fa-twitter"></i></a></li>
                            <li class="list-inline-item"><a href="https://www.youtube.com/channel/UCeT7emdH3Bx-w3-wrEjys_Q"><i class="fa fa-youtube"></i></a></li>
                        </ul>
                        <h4>Emails</h4>
                        <ul class="w3-ul">
                            <li>contact@bravewolfdev.com</li>
                            <li>contact@outlook.com</li>
                        </ul>
                    </div>
                    <div class="col-sm-6">
                        <form id="contacto" ref="formulario">
                            <div class="form-group">
                                <label>Full names</label>
                                <input type="text" id="txtusername" class="form-control" placeholder="Escribe tus nombres y apellidos" />
                            </div>
                            <div class="form-group">
                                <label>Email</label>
                                <input type="email" id="txtemail" class="form-control data-required" placeholder="tucorreo@example.com" />
                            </div>
                            <div class="form-group">
                                <label>Message</label>
                                <textarea id="txtmessage" class="form-control" rowspan="3"></textarea>
                            </div>
                            <div><button type="button" class="w3-block w3-button w3-indigo btn-sendemail" v-on:click="sendemail()">Send Message</button></div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
        <!--END CONTACT FORM -->
    </div>
</template>

<script>
export default {
    name: "Contact",
    components: {},
    data(){
        return {}
    },
    methods:{
        sendemail(){
            const formContainer = this.$refs.formulario
            const _URL = String.prototype.concat(window.GLOBAL.URL_BRAVEWOLF_OFFICE_API,"email/sendemail")
            let _d = new FormData();
            let continuar = true
            let _email,_username,_message,postdata
            formContainer.querySelectorAll('.data-required').forEach(el=>{
                el.classList.remove('empty')
                if(!el.value || el.value == '' || el.value.lenght){
                    el.classList.add('empty')
                    continuar = false
                }
            })
            if(!continuar){ this.$swal('Fields is empty',"Some fields is required",'warning'); return false; }
            this.$swal.fire({
                title: 'Sending mail, please wait',
                background: '#ffffff',
                showConfirmButton: false,
                allowOutsideClick: false,
                willOpen: () => {
                    this.$swal.showLoading();
                }
            })
            
            _email = formContainer.querySelector('#txtemail').value
            _username = formContainer.querySelector('#txtusername').value
            _message = formContainer.querySelector('#txtmessage').value

            let Message = `<div style="max-width:700px; border-left:1px solid gray; border-right:1px solid gray; padding:1em 3em; background:white; margin:0 auto;">
            <h1>Contacto desde la web, por el usuario: <strong>${_username}</strong></h1>
            <h4>Email de contacto: <strong>${_email}</strong></h4>
            <div style="padding:2em; border:1px dashed gray">${_message}</div>
            <h5 style="background-color:lightgray; font-weight:bold;">Correo enviado por el sistema BWOffice desde la web de BraveWolf</h5>
            </div>`
            postdata = {
                subject: 'Contacto desde la web bravewolfdev.com',
                email: 'contact@bravewolfdev.com',
                ccmail: 'emmyseco@gmail.com',
                username: 'emailman',
                password: 'emailman369258741',
                htmlcontent: btoa(Message)
            }
            
            Object.keys(postdata).forEach(e=>{ _d.append(e.toString(),postdata[e]); });
            
            fetch(_URL,{
                method:'POST',
                body:_d
            })
            .then(r=> r.json())
            .then(response => {
                this.$swal.close();
                if(response.code == 200){
                    this.$swal('Mail sent',"The Email was sent successfully",'success');
                }else{
                    this.$swal('Ops!',"Mail is not sent for any error, Mail was not sent for some fail, we are working on it",'error');
                }
            })
        }
    }
}
</script>

<style>
.main-contact{ background: -webkit-linear-gradient( 22deg, #1875e8 0%, #1C262B 100%); min-height: 520px; }
.data-required.empty { border:1px solid red; }
</style>